<div cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
@if (showEditBtn || showSaveAndExit || showDelete) {
<button
  tabindex="0"
  class="m-2 btn btn-ghost btn-circle btn-sm"
  (click)="onMenuClick()"
>
  <i class="text-xl icon-Open-Options"></i>
</button>
}

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayWidth]="width"
  [cdkConnectedOverlayOffsetX]="offsetX"
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayPositions]="positions"
  (backdropClick)="onClose()"
>
  <div
    class="box-border relative flex flex-wrap w-full px-4 py-3 border rounded-b-lg bg-midnight border-midnight shadow-input-focus"
  >
    <!-- Close button -->
    <img
      (click)="onClose()"
      class="absolute w-4 h-4 cursor-pointer top-3.5 right-3.5"
      alt="Close"
      src="assets/icons/close-light.svg"
    />
    <!-- Top wrapper -->
    <div class="flex items-center justify-between w-full">
      <!-- Text -->
      <div class="flex flex-col justify-between h-full max-w-[calc(100%-20px)]">
        <p
          class="mt-0 mb-1 font-medium leading-6 truncate text-primary-content"
        >
          {{ fileName }}
        </p>
        <span class="mb-2 font-normal truncate text-primary-content"
          >{{ totalPages || 'Counting' }} pages</span
        >
      </div>
    </div>

    <div class="w-full text-primary-content">
      <hr class="my-1 text-primary-content hy-4" />

      <ul tabindex="0" class="px-0 menu dropdown-content">
        <li *ngIf="showEditBtn" class="px-0 rounded-none">
          <a
            class="pl-0 no-underline transition-opacity active:bg-midnight"
            (click)="onEdit()"
          >
            <i class="mr-2 text-2xl text-primary-content icon-Edit"></i>
            Edit
          </a>
        </li>
        <li *ngIf="showSaveAndExit" class="px-0 rounded-none">
          <a
            class="pl-0 no-underline transition-opacity active:bg-midnight"
            (click)="onSaveAndExit()"
          >
            <i class="mr-2 text-2xl text-primary-content icon-Doc-Signed"></i>
            Save and exit
          </a>
        </li>
        <li *ngIf="showDelete" class="px-0 rounded-none">
          <a
            class="pl-0 no-underline transition-opacity active:bg-midnight"
            (click)="onDelete()"
          >
            <i class="mr-2 text-2xl text-primary-content icon-Delete"></i>
            Delete</a
          >
        </li>
      </ul>
    </div>
  </div>
</ng-template>
