import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  DocumentApiService,
  CompanyApiService,
  ApiConfiguration,
  ApiConfigurationParams,
  AuditService,
  VersionNumberInterceptor,
} from '..';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DocumentApiService,
    CompanyApiService,
    ApiConfiguration,
    AuditService,
  ],
})
export class ApiModule {
  static forRoot(
    params: ApiConfigurationParams,
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient,
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.',
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
