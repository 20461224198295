// Generated by https://quicktype.io

export interface VersionInfo {
  update: Update;
  current: string;
}

interface Update {
  versionInfo: Info;
  updateInfo: Info;
}

interface Info {
  version: string;
  files: File[];
  path: string;
  sha512: string;
  releaseDate: string;
  assets: Asset[];
}

interface Asset {
  url: string;
  id: number;
  node_id: string;
  name: string;
  label: string;
  uploader: Uploader;
  content_type: string;
  state: string;
  size: number;
  download_count: number;
  created_at: string;
  updated_at: string;
  browser_download_url: string;
}

interface Uploader {
  login: string;
  id: number;
  node_id: string;
  avatar_url: string;
  gravatar_id: string;
  url: string;
  html_url: string;
  followers_url: string;
  following_url: string;
  gists_url: string;
  starred_url: string;
  subscriptions_url: string;
  organizations_url: string;
  repos_url: string;
  events_url: string;
  received_events_url: string;
  type: string;
  site_admin: boolean;
}

interface File {
  url: string;
  sha512: string;
}

export interface WifiInfo {
  success: boolean;
  message: string;
  networks: {
    ssid: string;
    mac: string;
    signal_level: number;
    channel: number;
  }[];
}
