export interface PageTypeInput {
  pageType: string;
  confidence: number;
  data: object; // Additional data
}

export interface PageType {
  type: string; // Page type category
  pages: PageTypePage[];
}

export interface PageTypePage {
  pdfId: string; // PDF ID
  pdfPageNum: number; // PDF page number
  packagePageNum: number; // Package page number
  autotagPageOrder: number | null; // Autotag page order. Used to track arrangement of pages durint editing
  confidence?: number; // Confidence level
  data: any; // Additional data
}

export interface Pagination {
  currentPage: number;
  itemsOnPage: number;
  hasNextPage: boolean;
}

export interface MLToolPageTypesResponse {
  statusCode: number;
  status: boolean;
  message: any;
  data: MLToolPageTypes[];
  modelErrors: any;
}

export interface MLToolPageTypes {
  ingestion?: any;
  category: string;
  part?: any;
  confidence: number;
}

/**
 * Stacking file and page types after sorting
 */
export interface StackingOrderResult {
  file: File | null;
  pageTypes: MLToolPageTypes[];
  pageTypesData: any[]; // Data that was passed with pages
  flaggedPages: number[];
  autotagPageOrder: Array<number | null>;
}

export interface StackingListResponse {
  statusCode: number;
  status: boolean;
  message: any;
  data: StackingListData[];
  modelErrors: any;
}

export interface StackingListData {
  stackingId: string;
  name: string;
  docType: string;
}

export type BucketType = 'buyers' | 'sellers' | 'both';
