import { Injectable } from '@angular/core';
import {
  ClearNotaryOnboarding,
  GetCompany,
  GetFullProfile,
  SetAADUserWithClaims,
  SetCompany,
  SetFullProfile,
  SetNotaryOnboarding,
  SetNotaryStamp,
  SetNotaryStampAsPrimary,
  UpdateFullProfile,
} from './profile.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import {
  AADUserWithClaims,
  CompanyData,
  NotaryOnboarding,
  Profile,
  StampWithImage,
} from '@et/typings';
import { ProfileDto, ProfilesService } from '@escrowtab/v2profile-api-client';
import { CompanyService } from '@et/shared';

export interface ProfileStateModel {
  notaryStamps: StampWithImage[] | undefined;
  aadProfileWithClaims: AADUserWithClaims | undefined;
  company: CompanyData | undefined;
  fullProfile: Profile | undefined;
  notaryOnboarding: NotaryOnboarding | undefined;
  error: string | undefined;
}

@State<ProfileStateModel>({
  name: 'ProfileStore',
  defaults: {
    notaryStamps: undefined,
    aadProfileWithClaims: undefined,
    company: undefined,
    notaryOnboarding: undefined,
    fullProfile: undefined,
    error: undefined,
  },
})
@Injectable()
export class ProfileState {
  constructor(
    private profileService: ProfilesService,
    private companyService: CompanyService,
  ) {}

  @Selector()
  static v2ProfileId(state: ProfileStateModel) {
    const oid = state.aadProfileWithClaims?.idTokenClaims.oid;
    const localAccountId = state.aadProfileWithClaims?.localAccountId;
    return oid || localAccountId;
  }

  @Selector()
  static getProfileEmail(state: ProfileStateModel) {
    const emails = state.aadProfileWithClaims?.idTokenClaims.emails;
    return emails?.[0];
  }

  @Selector()
  static getCompany(state: ProfileStateModel) {
    return state.company;
  }

  @Selector()
  static getNotaryStamp(state: ProfileStateModel) {
    return state.notaryStamps;
  }

  @Selector()
  static getNotaryPrimaryStamp(
    state: ProfileStateModel,
  ): StampWithImage | undefined {
    const approvedStamps = state.notaryStamps?.filter(
      (stamp) => stamp.isApproved,
    );

    if (!approvedStamps || approvedStamps.length === 0) {
      return undefined;
    }

    const primaryStamps = approvedStamps.filter((stamp) => stamp.isPrimary);
    return primaryStamps.length >= 1 ? primaryStamps[0] : undefined;
  }

  @Selector()
  static getAadProfileWithClaims(state: ProfileStateModel) {
    return state.aadProfileWithClaims as AADUserWithClaims;
  }

  @Selector()
  static getNotaryOnboarding(state: ProfileStateModel) {
    return state.notaryOnboarding;
  }

  @Selector()
  static getUserType(state: ProfileStateModel) {
    return state.aadProfileWithClaims?.idTokenClaims.extension_et_user_type;
  }

  @Selector()
  static getAllProfiles(state: ProfileStateModel) {
    return state;
  }

  @Selector()
  static getFullProfile(state: ProfileStateModel) {
    return state.fullProfile;
  }

  @Selector()
  static isElsiEnabled(state: ProfileStateModel) {
    return state.company?.elsiEnabled;
  }

  @Action(SetAADUserWithClaims)
  setAADUserWithClaims(
    ctx: StateContext<ProfileStateModel>,
    payload: { profile: AADUserWithClaims },
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.aadProfileWithClaims = payload.profile;
    });
    ctx.setState(state);
  }

  @Action(GetFullProfile)
  getProfileFromApi(
    ctx: StateContext<ProfileStateModel>,
    payload: { profile: AADUserWithClaims },
  ) {
    this.profileService
      .getProfile({
        id: payload.profile.idTokenClaims.oid as string,
      })
      .subscribe(({ data }) => {
        ctx.dispatch(new SetFullProfile(data as Profile));
      });
  }

  @Action(SetFullProfile)
  setProfileFromApi(
    ctx: StateContext<ProfileStateModel>,
    payload: SetFullProfile,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.fullProfile = payload.profile;
    });
    ctx.setState(state);
  }

  @Action(UpdateFullProfile)
  updateFullProfile(
    ctx: StateContext<ProfileStateModel>,
    { profile }: UpdateFullProfile,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.fullProfile = { ...draft.fullProfile, ...profile };
    });
    ctx.setState(state);
  }

  @Action(GetCompany)
  getCompanyFromApi(
    ctx: StateContext<ProfileStateModel>,
    { companyId }: GetCompany,
  ) {
    return this.companyService
      .getCompanyById(companyId)
      .subscribe((company) => {
        ctx.dispatch(new SetCompany(company));
      });
  }

  @Action(SetCompany)
  setCompany(ctx: StateContext<ProfileStateModel>, { company }: SetCompany) {
    const state = produce(ctx.getState(), (draft) => {
      draft.company = company;
    });
    ctx.setState(state);
  }

  @Action(SetNotaryStamp)
  setNotaryStamp(
    ctx: StateContext<ProfileStateModel>,
    payload: { stamp: StampWithImage[] },
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.notaryStamps = payload.stamp;
    });
    ctx.setState(state);
  }

  @Action(SetNotaryStampAsPrimary)
  makeNotaryStampPrimary(
    ctx: StateContext<ProfileStateModel>,
    payload: { stampId: string },
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.notaryStamps = draft.notaryStamps?.map((s) => {
        s.isPrimary = s.stampId === payload.stampId;
        return s;
      });
    });
    ctx.setState(state);
  }

  @Action(SetNotaryOnboarding)
  setNotaryOnboarding(
    ctx: StateContext<ProfileStateModel>,
    { payload }: SetNotaryOnboarding,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.notaryOnboarding = { ...draft.notaryOnboarding, ...payload };
    });
    ctx.setState(state);
  }

  @Action(ClearNotaryOnboarding)
  clearNotaryOnboarding(ctx: StateContext<ProfileStateModel>) {
    const state = produce(ctx.getState(), (draft) => {
      draft.notaryOnboarding = undefined;
    });
    ctx.setState(state);
  }
}
