import {
  orderEnum,
  sortEnum,
  typeEnum,
  stateEnum,
  documentOptionsEnum,
  tileButtonType,
} from './../interfaces/select.interfaces';
import { selectOption } from '../interfaces/select.interfaces';

export const orderOptions: selectOption[] = [
  { value: orderEnum.ascending, name: 'Ascending', disabled: false },
  { value: orderEnum.descending, name: 'Descending', disabled: false },
];
export const sortOptions: selectOption[] = [
  { value: sortEnum.name, name: 'Name', disabled: false },
  { value: sortEnum.date, name: 'Date', disabled: false },
];
export const typeOptions: selectOption[] = [
  { value: typeEnum.all, name: 'All', disabled: false },
  { value: typeEnum.active, name: 'Active', disabled: false },
  { value: typeEnum.archived, name: 'Archived', disabled: false },
];
export const docStatusOptions: selectOption[] = [
  { value: stateEnum.all, name: 'All', disabled: false },
  { value: stateEnum.signed, name: 'Signed', disabled: false },
  { value: stateEnum.unsigned, name: 'Unsigned', disabled: false },
];
export const viewDocumentOptions: selectOption[] = [
  {
    value: documentOptionsEnum.eVault,
    name: 'View eVault Info',
    disabled: false,
  },
  {
    value: documentOptionsEnum.auditLog,
    name: 'View Audit Log',
    disabled: false,
  },
  { value: documentOptionsEnum.ocr, name: 'Send to OCR', disabled: false },
  { value: documentOptionsEnum.archive, name: 'Archive', disabled: false },
];
export const MAX_ITEMS_PER_PAGE = 30;
export const DEFAULT_FILTER_ORDER_OPTION = orderEnum.descending;
export const DEFAULT_FILTER_SORT_OPTION = sortEnum.date;
export const DEFAULT_FILTER_TYPE_OPTION = typeEnum.active;
export const DEFAULT_FILTER_STATE_OPTION = stateEnum.all;
export const MAX_FILE_SIZE_SUPPORT = 1000000 * 30; // 30mb in bytes
export const MAX_FILE_SIZE_WARN = 1000000 * 10; // 10mb in bytes

export const STANDARD_DATE_FORMAT = 'MMMM DD, YYYY';
export const DEFAULT_DATE_PICKER = {
  monthFormat: 'MMM, YYYY',
  disableKeypress: false,
  closeOnSelect: undefined,
  closeOnSelectDelay: 100,
  onOpenDelay: 0,
  weekDayFormat: 'dd',
  showNearMonthDays: true,
  showWeekNumbers: false,
  enableMonthSelector: true,
  yearFormat: 'YYYY',
  showGoToCurrent: true,
  dayBtnFormat: 'DD',
  monthBtnFormat: 'MMM',
  hours12Format: 'hh',
  hours24Format: 'HH',
  meridiemFormat: 'A',
  minutesFormat: 'mm',
  minutesInterval: 1,
  secondsFormat: 'ss',
  secondsInterval: 1,
  showSeconds: false,
  showTwentyFourHours: false,
  timeSeparator: ':',
  multipleYearsNavigateBy: 10,
  showMultipleYearsNavigation: false,
  hideInputContainer: false,
  returnedValueType: 3,
  unSelectOnClick: true,
  numOfMonthRows: 3,
  required: false,
  format: STANDARD_DATE_FORMAT,
};

export let DEFAULT_PAGE_SHARE_WIDTH = 920;
if (window.innerWidth < 1100) {
  DEFAULT_PAGE_SHARE_WIDTH = window.innerWidth - 32;
}

export const TILE_ESLI_UNSIGNED_BUTTONS_ORDER = [
  tileButtonType.Edit,
  tileButtonType.Preview,
  tileButtonType.Resend,
  tileButtonType.AuditLog,
  tileButtonType.Archive,
  tileButtonType.More,
];

export const TILE_ESLI_UNSIGNED_MENU_ORDER = [
  tileButtonType.SecureLinks,
  tileButtonType.Download,
  tileButtonType.Duplicate,
  tileButtonType.Void,
];

export const TILE_ESLI_SIGNED_BUTTONS_ORDER = [
  tileButtonType.Download,
  tileButtonType.Duplicate,
  tileButtonType.PostPrep,
  tileButtonType.AuditLog,
  tileButtonType.Archive,
  tileButtonType.More,
];

export const TILE_ESLI_SIGNED_MENU_ORDER = [tileButtonType.SecureLinks];

export const TILE_ET_UNSIGNED_BUTTONS_ORDER = [
  tileButtonType.Edit,
  tileButtonType.Preview,
  tileButtonType.Download,
  tileButtonType.Reassign,
  tileButtonType.Archive,
  tileButtonType.More,
];

export const TILE_ET_UNSIGNED_MENU_ORDER = [
  tileButtonType.SecureLinks,
  tileButtonType.Duplicate,
];

export const TILE_ET_SIGNED_BUTTONS_ORDER = [
  tileButtonType.Download,
  tileButtonType.PostPrep,
  tileButtonType.Duplicate,
  tileButtonType.AuditLog,
  tileButtonType.Archive,
  tileButtonType.More,
];

export const TILE_ET_SIGNED_MENU_ORDER = [tileButtonType.SecureLinks];

/** User to store merged file as draft */
export const MERGED_FILE_ID = 'mergedFile';
export const MERGED_FILE_NAME = 'unsigned.pdf';
