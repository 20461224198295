import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

import { Observable } from 'rxjs';
@Injectable()
export class VersionNumberInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const version = localStorage.getItem('ET_VERSION');
    if (version && request.url.includes('escrowtab.com')) {
      request = request.clone({
        setHeaders: {
          'ET-Version': version,
        },
      });
    }
    return next.handle(request);
  }
}
