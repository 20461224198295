import { openDB, IDBPDatabase, StoreValue, deleteDB } from 'idb';

export interface NativeAnnotation {
  page: string;
  value?: NativeAnnotationItem;
}
export interface NativeAnnotationItem {
  version: string;
  page: string;
  objects: AnnotationBackgroundImage[];
  backgroundImage: AnnotationBackgroundImage | null;
  hoverCursor: string;
}

export interface AnnotationBackgroundImage {
  type: string;
  version: string;
  originX: string;
  originY: string;
  left: number;
  top: number;
  width: number;
  height: number;
  fill: null | string;
  stroke: null | string;
  strokeWidth: number;
  strokeDashArray: null;
  strokeLineCap: string;
  strokeDashOffset: number;
  strokeLineJoin: string;
  strokeUniform: boolean;
  strokeMiterLimit: number;
  scaleX: number;
  scaleY: number;
  angle: number;
  flipX: boolean;
  flipY: boolean;
  opacity: number;
  shadow: null;
  visible: boolean;
  backgroundColor: string;
  fillRule: string;
  paintFirst: string;
  globalCompositeOperation: string;
  skewX: number;
  skewY: number;
  cropX?: number;
  cropY?: number;
  selectable: boolean;
  lockMovementX: boolean;
  lockMovementY: boolean;
  hasControls: boolean;
  hoverCursor: null;
  src?: string;
  crossOrigin?: null;
  filters?: unknown[];
  rx?: number;
  ry?: number;
  data?: AnnotationAnnotationBackgroundImageData;
  endTime?: number;
  strokePoints?: AnnotationStrokePoint[];
  startTime?: number;
  text?: string;
}

export interface AnnotationAnnotationBackgroundImageData {
  id?: string;
  image?: string;
  bottom?: number;
  isStamp?: boolean;
  matrixId?: string;
  keyboardId?: string;
}

export interface AnnotationStrokePoint {
  x: number;
  y: number;
  type: AnnotationStrokePointType;
  pressure: number;
}

export enum AnnotationStrokePointType {
  PSPoint = 'PSPoint',
}

interface MyDB extends IDBPDatabase {
  nativeAnnotationItem: StoreValue<MyDB, 'nativeAnnotationItem'>;
  nativeEnotesAnnotationItem: StoreValue<MyDB, 'nativeEnotesAnnotationItem'>;
}

let db: IDBPDatabase<MyDB>;
const dbName = 'ngdexieliveQuery';

async function initDB() {
  db = await openDB<MyDB>(dbName, 31, {
    upgrade(db) {
      if (!db?.objectStoreNames?.contains('nativeAnnotationItem')) {
        db.createObjectStore('nativeAnnotationItem', { keyPath: 'page' });
      }
      if (!db?.objectStoreNames?.contains('nativeEnotesAnnotationItem')) {
        db.createObjectStore('nativeEnotesAnnotationItem', { keyPath: 'page' });
      }
    },
    blocked() {
      // If the database is blocked, attempt to delete it after informing the user
      console.log(
        'Database is blocked. Attempting to delete the older version.',
      );
      deleteOldDB();
    },
  });
}

export async function addPage(
  page: string,
  value: NativeAnnotationItem,
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
) {
  try {
    if (!db) await initDB();

    // Check if the database is still valid before proceeding
    if (!db || db.version === undefined) {
      console.warn('Database connection is not available or is closing');
      return;
    }

    const tx = db.transaction(type, 'readwrite');
    const store = tx.objectStore(type);
    await store.put({ page: page, value: value });
    await tx.done;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
    // If the error is related to a closing database, we can log a more specific message
    if (error instanceof DOMException && error.name === 'InvalidStateError') {
      console.warn('Database connection is closing. Operation skipped.');
    }
  }
}

export async function findByPage(
  page: string,
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
): Promise<NativeAnnotation> {
  try {
    if (!db) await initDB();
    const tx = db.transaction(type, 'readonly');
    const store = tx.objectStore(type);
    const result = await store.get(page);
    await tx.done;
    return result;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
    return { page, value: undefined };
  }
}

export async function createOrUpdateByAnnotationByPage(
  page: string,
  annotation: NativeAnnotationItem,
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
) {
  try {
    if (!db) await initDB();

    // Check if the database is still valid before proceeding
    if (!db || db.version === undefined) {
      console.warn('Database connection is not available or is closing');
      return;
    }

    const tx = db.transaction(type, 'readwrite');
    const store = tx.objectStore(type);
    const existingPage = await store.get(page);
    if (existingPage?.value?.objects) {
      const combineAnnotation = [
        ...existingPage.value.objects,
        ...annotation.objects,
      ];
      await store.put({
        page: page,
        value: { ...existingPage.value, objects: combineAnnotation },
      });
    } else {
      await store.put({ page: page, value: annotation });
    }
    await tx.done;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
    // If the error is related to a closing database, we can log a more specific message
    if (error instanceof DOMException && error.name === 'InvalidStateError') {
      console.warn('Database connection is closing. Operation skipped.');
    }
  }
}
export async function editOrUpdateTextboxByAnnotationByPage(
  page: string,
  annotation: NativeAnnotationItem,
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
) {
  try {
    if (!db) await initDB();

    // Check if the database is still valid before proceeding
    if (!db || db.version === undefined) {
      console.warn('Database connection is not available or is closing');
      return;
    }

    const tx = db.transaction(type, 'readwrite');
    const store = tx.objectStore(type);
    const existingPage = await store.get(page);
    if (existingPage?.value?.objects) {
      if (
        annotation.objects.length > 0 &&
        annotation.objects[0].type === 'textbox'
      ) {
        const textbox = existingPage.value.objects.findIndex(
          (obj: any) =>
            obj?.type === 'textbox' &&
            obj?.data?.keyboardId === annotation.objects[0].data?.keyboardId &&
            obj?.data?.matrixId === annotation.objects[0].data?.matrixId,
        );
        existingPage.value.objects[textbox] = annotation.objects[0];
        await store.put({
          page: page,
          value: { objects: existingPage.value.objects },
        });
      }
    } else {
      await store.put({ page: page, value: annotation });
    }
    await tx.done;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
    // If the error is related to a closing database, we can log a more specific message
    if (error instanceof DOMException && error.name === 'InvalidStateError') {
      console.warn('Database connection is closing. Operation skipped.');
    }
  }
}
export async function deleteOrUpdateTextboxByAnnotationByPage(
  page: string,
  annotation: NativeAnnotationItem,
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
) {
  try {
    if (!db) await initDB();

    // Check if the database is still valid before proceeding
    if (!db || db.version === undefined) {
      console.warn('Database connection is not available or is closing');
      return;
    }

    const tx = db.transaction(type, 'readwrite');
    const store = tx.objectStore(type);
    const existingPage = await store.get(page);
    if (existingPage?.value?.objects) {
      if (
        annotation.objects.length > 0 &&
        annotation.objects[0].type === 'textbox'
      ) {
        const textbox = existingPage.value.objects.findIndex(
          (obj: any) =>
            obj?.type === 'textbox' &&
            obj?.data?.keyboardId === annotation.objects[0].data?.keyboardId &&
            obj?.data?.matrixId === annotation.objects[0].data?.matrixId,
        );
        existingPage.value.objects.splice(textbox, 1);
        await store.put({
          page: page,
          value: { objects: existingPage.value.objects },
        });
      }
    } else {
      await store.put({ page: page, value: annotation });
    }
    await tx.done;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
    // If the error is related to a closing database, we can log a more specific message
    if (error instanceof DOMException && error.name === 'InvalidStateError') {
      console.warn('Database connection is closing. Operation skipped.');
    }
  }
}

export async function deleteByPage(
  page: string,
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
) {
  try {
    if (!db) await initDB();
    const tx = db.transaction(type, 'readwrite');
    const store = tx.objectStore(type);
    await store.delete(page);
    await tx.done;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
  }
}

async function deleteOldDB() {
  try {
    await deleteDB(dbName, {
      blocked() {
        // This will be executed if the delete operation is blocked
        console.log(
          'Delete operation is blocked by an open connection to the old database.',
        );
      },
    });
    console.log(
      'Old database deleted successfully. Please refresh the page to continue.',
    );
  } catch (error) {
    console.error('Failed to delete the old database:', error);
  }
}

export async function clearAll() {
  try {
    if (!db) await initDB();

    // Check if the database is still valid before proceeding
    if (!db || db.version === undefined) {
      console.warn('Database connection is not available or is closing');
      return;
    }

    const tx = db.transaction(
      ['nativeAnnotationItem', 'nativeEnotesAnnotationItem'],
      'readwrite',
    );
    await tx.objectStore('nativeAnnotationItem').clear();
    await tx.objectStore('nativeEnotesAnnotationItem').clear();
    await tx.done;
  } catch (error) {
    console.error('Failed to clear database:', error);
    // If the error is related to a closing database, we can log a more specific message
    if (error instanceof DOMException && error.name === 'InvalidStateError') {
      console.warn('Database connection is closing. Clear operation skipped.');
    }
  }
}

export async function returnAsKeys(
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
): Promise<string[]> {
  try {
    if (!db) await initDB();
    const tx = db.transaction(type, 'readonly');
    const store = tx.objectStore(type);
    const keys = await store.getAllKeys();
    await tx.done;
    return keys as string[];
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
    return [];
  }
}

export async function IndexInsertFromMap(
  cache: Map<string, NativeAnnotationItem>,
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
) {
  try {
    if (!db) await initDB();
    const tx = db.transaction(type, 'readwrite');
    const store = tx.objectStore(type);

    for (const [key, value] of cache.entries()) {
      if (!value) continue;
      await store.put({ page: key, value }); // put method updates existing record or insert a new one.
    }

    await tx.done;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
  }
}
export async function IndexReturnAsMap(
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
) {
  try {
    if (!db) await initDB();
    const annotationAll = new Map();
    const tx = db.transaction(type, 'readonly');
    const store = tx.objectStore(type);
    let cursor = await store.openCursor();

    while (cursor) {
      if (cursor.value.value) {
        annotationAll.set(cursor.key, cursor.value.value);
      }
      cursor = await cursor.continue();
    }
    await tx.done;
    return annotationAll;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
    return new Map();
  }
}

export async function CheckIfIndexDBIsActive(
  type:
    | 'nativeAnnotationItem'
    | 'nativeEnotesAnnotationItem' = 'nativeAnnotationItem',
) {
  try {
    if (!db) await initDB();
    const tx = db.transaction(type, 'readonly');
    const store = tx.objectStore(type);
    const result = await store.get('1');
    await tx.done;
    return !!result;
  } catch (error) {
    console.error('DB OPERATION FAILED', error);
    return false;
  }
}
