import { CustomNavigationAndroidClient } from './custom-navigation-client-android';
import { IpcMessages } from '@et/electron';
import { ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Capacitor } from '@capacitor/core';
import { platformType } from '@et/typings';
import { CustomNavigationClient } from './custom-navigation-client';
import { isOnline } from '@et/utils';
import packageInfo from '@et/package';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  incorrectCred = false;
  form: UntypedFormGroup = this.createForm();
  returnUrl!: string;
  submitted = false;
  deviceIsNative = this.isNative();
  isOnline = isOnline();
  version = packageInfo?.nativeVersion;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {}

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  /**
   * It calls the login function of the apiService and passes in the email and password.
   * @param  - `email`: The email of the user.
   */
  login({ email }: { email: string }) {
    // Check internet connection
    if (!isOnline()) {
      this.form.get('email')?.setErrors({ isOffline: true });
      return;
    }
    // Login
    const platform = Capacitor.getPlatform();
    if (platform === platformType.ELECTRON) {
      this.loginElectron(email);
    } else if (platform === platformType.IOS) {
      this.loginIOS(email);
    } else if (platform === platformType.ANDROID) {
      this.loginAndroid(email);
    } else {
      this.loginWeb(email);
    }
    this.submitted = true;
  }

  /**
   * It sends a message to the main process to open the login window, and then waits for a response from
   * the main process
   * @param {string} [email] - The email address of the user to log in. If not provided, the user will be
   * prompted to enter their email address.
   */
  private loginElectron(email: string) {
    window.api?.send(IpcMessages.LOGIN, { email });
  }

  /**
   * It takes the email address of the user, and then uses it to configure the login request
   * for IOS
   * @param {string} email - The email address of the user to login.
   */
  private loginIOS(email: string) {
    const navigationClient = new CustomNavigationClient();
    this.authService.instance.setNavigationClient(navigationClient);
    let authRequestConfig = {} as RedirectRequest;

    if (this.msalGuardConfig.authRequest) {
      authRequestConfig = {
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest;
    }

    authRequestConfig.loginHint = email;
    authRequestConfig.domainHint = email.split('@')[1];
    authRequestConfig.redirectStartPage = this.returnUrl || '/';

    this.authService.loginRedirect(authRequestConfig);
  }
  /**
   * It takes the email address of the user, and then uses it to configure the login request
   * for Android
   * @param {string} email - The email address of the user to login.
   */
  private loginAndroid(email: string) {
    const navigationClient = new CustomNavigationAndroidClient();
    this.authService.instance.setNavigationClient(navigationClient);
    let authRequestConfig = {} as RedirectRequest;

    if (this.msalGuardConfig.authRequest) {
      authRequestConfig = {
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest;
    }

    authRequestConfig.loginHint = email;
    authRequestConfig.domainHint = email.split('@')[1];
    authRequestConfig.redirectStartPage = this.returnUrl || '/';

    this.authService.loginRedirect(authRequestConfig);
  }

  /**
   * It takes the email address of the user, and then uses it to configure the login request
   * @param {string} email - The email address of the user to login.
   */
  private loginWeb(email: string) {
    let authRequestConfig = {} as RedirectRequest;

    if (this.msalGuardConfig.authRequest) {
      authRequestConfig = {
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest;
    }

    authRequestConfig.loginHint = email;
    authRequestConfig.domainHint = email.split('@')[1];
    authRequestConfig.redirectStartPage = this.returnUrl || '/';

    this.authService.loginRedirect(authRequestConfig);
  }
  /**
   * check if the device is native
   *
   * @return {*}  {boolean}
   * @memberof LoginComponent
   */
  isNative(): boolean {
    const platform = Capacitor.getPlatform();
    return (
      platform === platformType.ELECTRON ||
      platform === platformType.IOS ||
      platform === platformType.ANDROID
    );
  }

  /**
   * Create a form group with the email and password fields
   * @returns The form group.
   */
  private createForm() {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }
}
