import {
  init,
  track,
  Identify,
  identify,
  reset,
} from '@amplitude/analytics-browser';
import { AccountInfo } from '@azure/msal-browser';
import { environment } from '@et/environment';

export enum trackingEvents {
  nativeLogIn = 'native_log_in',
  nativeStartSigning = 'native_start_signing',
  nativeCompleteVerify = 'native_complete_verify',
  nativeVersionNumber = 'native_version_number',
  nativeOpenScrubber = 'native_open_scrubber',
  nativeSaveDocFromDocList = 'native_save_doc_from_doc_list',
  nativeSaveAndExitFromExit = 'native_save_and_exit_from_exit',
  nativeAllSignStart = 'native_all_sign_start',
  nativeAllSignStop = 'native_all_sign_stop',
  nativeRedoOnSigning = 'native_redo_on_signing',
  nativeUndoOnSigning = 'native_undo_on_signing',
  nativeAppZoom = 'native_app_zoom',
  nativeResetPacket = 'native_reset_packet',
  nativeAllsignErrorPopup = 'native_allsign_error_popup',
  organizePagesOnUpload = 'organize_pages_on_upload',
  webUploadedNotaryInfoStamp = 'web_uploaded_notary_info_stamp',
  webEJournalLogIn = 'web_ejournal_log_in',
  webCreatedEntryEJournal = 'web_created_entry_ejournal',
  webStartVerificationSession = 'web_start_verification_session',
  webUploadedPacket = 'web_uploaded_packet',
  webAssignedPacket = 'web_assigned_packet',
  webDownloadedCompletedPacket = 'web_downloaded_completed_packet',
  webCreateOrder = 'web_created_order',
  webUpdatedOrder = 'web_updated_order',
  webAssignedOrderToTitle = 'web_assigned_order_to_title',
  webSendSecureLinks = 'web_send_secure_links',
  webOpenShare = 'web_open_share',
  webEditDialog = 'web_edit_dialog',
  webDuplicateDialog = 'web_duplicate_dialog',
  webPostPrepWithSubsets = 'web_post_prep_with_subsets',
  nativeAllSignInstructionsBack = 'native_all_sign_instructions_back',
  nativeAllSignInstructionsView = 'native_all_sign_instructions_view',
  nativeAllSignConnectionEstablished = 'native_all_sign_connection_established',
}

/**
 * It initializes the Amplitude SDK with the API key from the environment file
 */
export function initTracking() {
  init(environment.amplitude);
}

/**
 * It takes a name and an event, and calls the track function with those two arguments
 * @param {string} name - The name of the event.
 * @param event - The name of the event you're tracking.
 */
export function trackEv(name: string, event: Record<string, any>) {
  track(name, event);
}

/**
 * It takes an account object, and uses it to set the user's identity in Segment
 * @param {AccountInfo} account - AccountInfo - This is the object that is returned from the login
 * process.
 */
export function setUser(account: AccountInfo) {
  const event = new Identify();

  event.set('email', account.username);
  event.set(
    'company_id',
    account?.idTokenClaims?.['extension_et_company_id'] as string,
  );
  event.set(
    'is_company_admin',
    account?.idTokenClaims?.['extension_et_is_company_admin'] as string,
  );
  event.set(
    'profile_id',
    account?.idTokenClaims?.['extension_et_profile_id'] as string,
  );
  event.set(
    'user_type',
    account?.idTokenClaims?.['extension_et_user_type'] as string,
  );

  identify(event);
}

/**
 * It takes a user object and resets it
 */
export function resetUser() {
  reset();
}
