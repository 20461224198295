<!-- Header -->
<div class="flex justify-between">
  <h2>Add local files</h2>
  <button mat-dialog-close>
    <img src="assets/icons/close.svg" width="16" height="16" alt="Close" />
  </button>
</div>

<!-- Upload file zone -->
<form
  class="flex flex-col gap-4 grow"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <file-upload
    formControlName="files"
    [animation]="false"
    [multiple]="true"
    class="grow"
  >
    <!-- Use custom placeholder -->
    <ng-template #placeholder>
      @if(!form.controls.files.value?.length) {
      <p class="text-base text-midnight">Drop files or click here</p>
      } @else {
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
        />
      </svg>
      }
    </ng-template>
  </file-upload>

  <!-- Errors -->
  @if(form.controls.files.touched && form.controls.files.errors) {
  <div>
    <!-- Required error -->
    @if(form.controls.files.hasError('required')) {
    <p class="text-sm text-error">Please, select a file</p>
    }

    <!-- File type error -->
    @if(form.controls.files.hasError('fileTypes')) {
    <!-- Error message for each file -->
    @for(file of form.controls.files.errors?.['fileTypes']; track $index){
    <p class="text-sm text-error">Only PDF files are allowed</p>

    } }

    <!-- File size error -->
    @if(form.controls.files.hasError('fileSize')) {
    <!-- Error message for each file -->
    @for(file of form.controls.files.errors?.['fileSize']; track $index){
    <p class="text-sm text-error">
      File size should be less than
      {{ convertBytesToMB(file.maxSize) }}MB
    </p>
    } }
  </div>
  }

  <!-- Buttons -->
  <div class="flex justify-end gap-4">
    <button
      mat-dialog-close
      type="button"
      class="normal-case rounded-full btn btn-outline min-w-[184px] text-base"
    >
      Cancel
    </button>
    <button
      type="submit"
      class="normal-case rounded-full btn btn-primary min-w-[184px] text-base"
    >
      Add files
    </button>
  </div>
</form>
