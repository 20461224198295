import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@et/environment';
import { StackingOrder, StackingOrderResponse } from '@et/typings';
import { Observable, map, catchError, of, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StackOrderService {
  private http = inject(HttpClient);

  /**
   * Gets the stacking order for the specified profile.
   *
   * This method calls the API to retrieve the stacking order for the specified profile ID.
   * If the stacking order is not found (404 error), it returns null.
   *
   * @param {string} profileId - The ID of the profile to get the stacking order for.
   * @returns {Observable<StackingOrder | null>} An observable that emits the stacking order or null if not found.
   */
  getStackingOrder(profileId: string): Observable<StackingOrder | null> {
    return this.http
      .get<StackingOrderResponse>(
        environment.profilev2.domain + `/api/StackOrder/${profileId}`,
      )
      .pipe(
        map((res) => res.data),
        catchError((err) => {
          if (err.status === 404) {
            return of(null);
          }
          throw err;
        }),
        retry({ count: 3, delay: 300 }),
      );
  }

  /**
   * Sets the stacking order for the specified profile.
   *
   * This method calls the API to set the stacking order for the specified profile ID.
   *
   * @param {string} profileId - The ID of the profile to set the stacking order for.
   * @param {StackingOrder} stackOrder - The stacking order to set.
   */
  setStackingOrder(profileId: string, stackOrder: StackingOrder) {
    const params = new HttpParams().append('profileId', profileId);
    return this.http.post(
      environment.profilev2.domain + `/api/StackOrder/`,
      stackOrder,
      { params },
    );
  }

  /**
   * Deletes the stacking order for the specified profile.
   *
   * This method calls the API to delete the stacking order for the specified profile ID.
   *
   * @param {string} profileId - The ID of the profile to delete the stacking order for.
   */
  deleteStackingOrder(profileId: string) {
    return this.http.delete(
      environment.profilev2.domain + `/api/StackOrder/${profileId}`,
    );
  }
}
