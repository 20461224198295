import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import {
  FileUploadComponent,
  FileUploadValidators,
} from '@iplab/ngx-file-upload';

export type FileUploadLocalDialogResponse = File[] | null | undefined;

@Component({
  selector: 'et-atoms-file-upload-local',
  standalone: true,
  imports: [FileUploadComponent, ReactiveFormsModule, MatDialogClose],
  templateUrl: './file-upload-local.component.html',
  styleUrl: './file-upload-local.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadLocalComponent {
  private dialogRef = inject(MatDialogRef);

  form = this.makeForm();

  /**
   * Submits the form if it is valid.
   *
   * This function checks if the form is valid. If the form is invalid, it returns early.
   * If the form is valid, it retrieves the files from the form value and closes the dialog,
   * passing the files as the result.
   */
  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const files = this.form.value.files;
    this.dialogRef.close(files);
  }

  /**
   * Converts a given size in bytes to megabytes.
   *
   * @param {number} bytes - The size in bytes to be converted.
   * @returns {number} - The size in megabytes.
   */
  convertBytesToMB(bytes: number): number {
    return bytes / (1024 * 1024);
  }

  /**
   * Creates and returns a new form group for file uploads.
   *
   * This function creates a new form group with a single form control named 'files'.
   * The 'files' form control is initialized with a null value and has two validators:
   * - `FileUploadValidators.accept(['.pdf'])` to ensure only PDF files are accepted.
   * - `FileUploadValidators.fileSize(20 * 1024 * 1024)` to ensure the file size does not exceed 20 MB.
   *
   * @private
   */
  private makeForm() {
    return new FormGroup({
      files: new FormControl<File[] | null>(null, [
        Validators.required,
        FileUploadValidators.accept(['.pdf']),
        FileUploadValidators.fileSize(20 * 1024 * 1024), // 20 MB
      ]),
    });
  }
}
